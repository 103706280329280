import React from 'react'
import PropTypes from 'prop-types'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><a onClick={props.onToggleMenu} href="/">Home</a></li>
                <li><a onClick={props.onToggleMenu} href="/speisekarte">Speisekarte</a></li>
                <li>
                    <a>Standorte</a>
                    <ul className="sub-links">
                        <li><a onClick={props.onToggleMenu} href="/floersheim-dalsheim">- Flörsheim-Dalsheim</a></li>
                        <li><a onClick={props.onToggleMenu} href="/monsheim">- Monsheim</a></li>
                    </ul>
                </li>

                <li><a onClick={props.onToggleMenu} href="#opening">Öffnungszeiten</a></li>
                <li><a onClick={props.onToggleMenu} href="#contact">Kontakt</a></li>
                {/* <li>-</li>
                <li><a onClick={props.onToggleMenu} href="/privacy">Öffnungszeiten</a></li>
                <li><a onClick={props.onToggleMenu} href="#contact">Kontakt</a></li> */}
            </ul>
        
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
