import React from 'react'


const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <h3>Folgen Sie uns</h3>
            <ul className="icons">
                <li>Flörsheim-Dalsheim:</li>
                <li><a href="https://www.facebook.com/dogandonerpizza" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.instagram.com/dogandonerpizzahaus" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
            </ul>
            <ul className="icons">
                <li>Monsheim:</li>
                <li><a href="https://www.facebook.com/dogandonerpizzahausmonsheim" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.instagram.com/dogandonerpizzahausmonsheim" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
            </ul>
            <ul className="icons">
                <li> <h3>Bewerten Sie uns Auf:</h3></li>
                <li><a href="https://www.tripadvisor.com/Restaurant_Review-g7160361-d12161864-Reviews-Dogan_Doner_Pizza_Haus-Floersheim_Dalsheim_Rhineland_Palatinate.html" className="icon alt fa-tripadvisor"><span className="label">Tripadvisor</span></a></li>
                <li><a href="https://www.yelp.com/biz/dogan-d%C3%B6ner-pizza-haus-fl%C3%B6rsheim-dalsheim" className="icon alt fa-yelp"><span className="label">Yelp</span></a></li>
            </ul>
            <ul className="copyright">
                <li>&copy;Dogan Döner Pizza Haus </li><li>Design & Development: <a href="https://www.onlinefoodorapp.com">https://www.onlinefoodorapp.com/</a></li>
            </ul>
            <nav id="footer-menu">
                <ul className="links">
                    <li><a onClick={props.onToggleMenu} href="/imprint">Impressum</a></li>
                    <li><a onClick={props.onToggleMenu} href="/privacy">Datenschutz</a></li>
                </ul>
            </nav>
        </div>
    </footer>
)

export default Footer
