import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">

            <section>
            <header className="major">
                <h2>Kontakt</h2>
            </header>
                <form action="https://getform.io/f/b37f93e5-d39e-4740-bcdf-8abce4ad79ab" method="POST" acceptCharset="UTF-8">
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input required type="text" name="name" id="name" />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input required type="email" name="email" id="email" />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea required minLength="20" name="message" id="message" rows="6"></textarea>
                    </div>
                    <ul className="actions">
                        <li><input type="submit" value="Senden" className="special" /></li>
                        <li><input type="reset" value="Löschen" /></li>
                    </ul>
                </form>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:dogandonerpizza@gmail.com">dogandonerpizza@gmail.com</a>
                    </div>
                </section>

                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3> Flörsheim-Dalsheim</h3>
                        <span> <strong>Address:</strong>  Alzeyer Str. 123A, <br />  67592 Flörsheim-Dalsheim</span> <br />
                        <span className="icon alt fa-phone"></span>
                        <h3></h3>
                        <a href="tel:004962439001216"> <strong>Tel:</strong> 06243 9001216</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3> Monsheim</h3>
                        <span><strong>Address:</strong> Robert-Bosch-Straße 2, <br />  67590 Monsheim</span> <br />
                        <span className="icon alt fa-phone"></span>
                        <a href="tel:004962439079654"> <strong>Tel:</strong> 06243 9079654</a>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
