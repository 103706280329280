import React from 'react'
import Helmet from 'react-helmet'

import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Contact from './Contact'
import Footer from './Footer'

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');
    require("cookieConsent");
  }
  

class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuVisible: false,
            loading: 'is-loading'
        }
        this.handleToggleMenu = this.handleToggleMenu.bind(this)
    }

    componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
    }

    componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleToggleMenu() {
        this.setState({
            isMenuVisible: !this.state.isMenuVisible
        })
    }

    render() {
        const { children } = this.props

        return (
            <div>
                <Helmet
                    title="Dogan Döner Pizza Haus - Flörsheim-Dalsheim - Monsheim - Online essen bestellen"
                    meta={[
                        { name: 'description', content: 'Bestellen Sie online zum Mitnehmen oder reservieren Sie einen Tisch. Bei Dogan Döner Pizza Haus Monsheim oder Flörsheim-Dalsheim erleben Sie köstliche Pizza, Sandwiches, Salads, Fast Food, Kebab, Turkish Küche. Probieren Sie unsere köstlichen Gerichte aus, die sorgfältig aus frischen Zutaten zubereitet werden! Bei Flörsheim-Dalsheim Monsheim ist unser Erfolgsrezept einfach - Großartiges Essen & Sorgfalt lässt Kunden immer wieder kommen.' },
                        { name: 'keywords', content: 'Döner, Pizza, Lahmacun, Pide, Salat, Falalafel, Döner Teller, Pizza in der Nähe, Pizzeria Flörsheim-Dalsheim Monsheim, Döner Flörsheim-Dalsheim Monsheim, Döner pizza, Pizza Bestellen, Heimservice, Lieferservice, Döner in der nähe, Flörsheim-Dalsheim Monsheim Döner, Flörsheim-Dalsheim Monsheim Pizza, Flörsheim-Dalsheim Monsheim Pide, Flörsheim-Dalsheim Monsheim Salat' },
                    ]}
                    
                    
                >
                <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css" />
                    <script type='application/ld+json'>{`
                        {
                            “@context”: “http://schema.org”,
                            “@type”: “Restaurant”,
                            "@id":"https://dogandonerpizza.com",
                            “name”: “Dogan Döner Pizza Haus”,
                            “url”: “https://dogandonerpizza.com/”,
                            “logo”: “https://dogandonerpizza.com/static/dogan-logo.png”,
                            "acceptsReservations": "https://www.dogandonerpizza.com/",
                            "menu": "https://www.dogandonerpizza.com/speisekarte/",
                            "description":"BESTE RESTAURANT MIT LIEFERSERVICE IN FLÖRSHEIM-DALSHEIM, MONSHEIM UND UMGEBUNG",
                            "paymentAccepted": "Cash, Credit Card",
                            "priceRange": "$$",
                            "servesCuisine": [
                                "Döner",
                                "Pizza",
                                "Pide",
                                "Lahmacun",
                                "Salat",
                                "Schnitzel",
                                "Steak",
                                "Kebap",
                                "Gätränke",
                                "Kaffee",
                                "Pommes",
                                "Snacks"
                            ],
                            "currenciesAccepted": "Euro",
                            "logo": {
                                "@type": "ImageObject",
                                "caption": "Dogan Döner Pizza Haus",
                                "embedUrl": "https://www.dogandonerpizza.com/",
                                "encodingFormat": "png"

                            
                            }
                            “location”:
                            [
                                {
                                “@type”: “Restaurant”,
                                “parentOrganization”: {
                                “name”: “Dogan Döner Pizza Haus”
                                },
                                “name” : “Dogan Döner Pizza Haus – Flörsheim-Dalsheim”,
                                “image”: “https://dogandonerpizza.com/static/dogan-logo.png”,
                                “address”: {
                                “@type”: “PostalAddress”,
                                “addressLocality”: “Floersheim-Dalsheim”,
                                "addressCountry":"Germany",
                                “addressRegion”: “Rheinland-Pfalz”,
                                “postalCode”: "67592",
                                “streetAddress”: “Alzeyer Str. 123A”
                                },
                                "openingHours":[
                                    "Tu-Sa 11:00-22:00",
                                    "Su 12:00-22:00",
                                 ],
                                “telephone” : “+4962439001216”
                                },
                                {
                                “@type”: “Bakery”,
                                “parentOrganization”: {
                                “name”: “Dogan Döner Pizza Haus”
                                },
                                “name” : “Dogan Döner Pizza Haus – Monsheim,
                                “image”: “https://dogandonerpizza.com/static/dogan-logo.png”,
                                “address”: {
                                “@type”: “PostalAddress”,
                                "addressCountry":"Germany",
                                “addressLocality”: “Monsheim”,
                                “addressRegion”: “Rheinland-Pfalz”,
                                “postalCode”: “67590”,
                                “streetAddress”: “Robert-Bosch-Straße 2”
                                },
                                "openingHours":[
                                    "Tu-Sa 11:00-22:00",
                                    "Su 12:00-22:00",
                                 ],
                                 "hasMenu": [
                                    {
                                     "@type": "Menu",
                                     "name": "Döner",
                                     "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                                    {
                                        "@type": "Menu",
                                        "name": "Pizza",
                                        "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                                    {
                                        "@type": "Menu",
                                        "name": "Lahmacun",
                                        "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                                    {
                                        "@type": "Menu",
                                        "name": "Schnitzel",
                                        "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                                    {
                                        "@type": "Menu",
                                        "name": "Hamburger",
                                        "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                                    {
                                        "@type": "Menu",
                                        "name": "Pide",
                                        "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                                    {
                                        "@type": "Menu",
                                        "name": "Salate",
                                        "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                                    {
                                        "@type": "Menu",
                                        "name": "Essen Liefern Lassen",
                                        "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                                    {
                                        "@type": "Menu",
                                        "name": "Lieferservice",
                                        "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                                    {
                                        "@type": "Menu",
                                        "name": "Dogan Speisekarte",
                                        "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                                    {
                                        "@type": "Menu",
                                        "name": "Dogan Lieferservice",
                                        "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                                    {
                                        "@type": "Menu",
                                        "name": "Dogan Monsheim",
                                        "url": "https://www.dogandonerpizza.com/speisekarte/"
                                    },
                         
                                    ],
                                “telephone” : “+496243 9079654”
                                }
                            ]
                        }
                    `}</script>
                     <script src="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js" data-cfasync="false"></script>
                    <script>{`
                    
                    window.cookieconsent.initialise({
                    "palette": {
                        "popup": {
                        "background": "#ffffff",
                        "text": "#1f1d1d"
                        },
                        "button": {
                        "background": "#0a0a0a",
                        "text": "#fffefe"
                        }
                    },
                    "position": "bottom-left",
                    "content": {
                        "message": "Um sicherzustellen, dass unsere Website einwandfrei funktioniert und um diese fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Website stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in ",
                        "dismiss": "Verstanden",
                        "link": "details",
                        "href": "http://dogandonerpizza.com/privacy"
                    }
                    });
                    `}
                    </script>
                    <script src="https://www.fbgcdn.com/embedder/js/ewm2.js" defer async ></script>
                    <html lang="en" />

                </Helmet>
                
                <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
                <div id="wrapper">
                    <Header onToggleMenu={this.handleToggleMenu} />
                    {children}
                    <Contact />
                    <Footer />
                </div>
                <Menu onToggleMenu={this.handleToggleMenu} />
            </div>
            </div>
        )
    }
}

export default Layout
