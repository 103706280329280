import React from 'react'
import Stickyheader from 'react-sticky-header'
import 'react-sticky-header/styles.css'
import Logo from '../assets/images/dogan-logo.png'



const Header = (props) => (
    <Stickyheader header={
        <header id="header"  className="alt" style={{backgroundColor:'rgba(0, 0, 0, 0.3)'}}>
            <a href="/" className="logo"><img src={Logo} alt="Dogan Döner Pizza Haus Logo" /></a>
            <nav>
                <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
            </nav>
        </header>
    }
    >
    </Stickyheader>
)



export default Header
